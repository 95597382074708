@import "../../../../resources/styles/variables.scss";
.mainPagination {
  flex-direction: column;
  align-items: center;
  p {
    font-size: 0.8rem;
    color: grey;
  }

  .pagination {
    margin: 5px;
  }

  .pagination-btn_wrapper {
    justify-content: center;
    display: flex;

    .btn {
      &:first-child {
        margin-right: 10px;
      }
    }

    .page-item {
      .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: 1px solid #86929e;
        background-color: transparent;
        color: #333333;
        font-size: 0.85rem;
        margin-right: 5px;
      }

      &.active {
        .page-link {
          border-color: transparent;
          color: #ffffff;
          background-color: $primary;
        }
      }

      &.disabled {
        a {
          background-color: #f1f1f1;
          opacity: 0.8;
        }
      }
    }
  }

  .btn {
    &:focus {
      box-shadow: none;
    }
  }
}
