.terms-main-container {
    padding: 20px;
  }
  
  .terms-heading-container {
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .terms-editor-title{
      color: #F36633;
      font-weight: 700;
  }
  
  .ck-editor__editable_inline {
    min-height: 400px;
  }
  
.term-save-button{
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  font-weight: 500;
  width: 100px;
}

.term-save-button:active{
  background: #d45020;
}