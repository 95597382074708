.file-uploader {
  .dropzone {
    min-height: 100px;
    margin-bottom: 20px;
    border: dashed 1px rgba(0, 0, 0, 0.3);
    @media (max-width: 768px) {
      padding: 0 20px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .attachment {
    font-size: 30px;
    color: #17a2b8;
  }

  .inputField-label {
    color: #ee743b;
  }
}
