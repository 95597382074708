@import '../../../../resources//styles//variables.scss';

.exportFile {
  .dropdown {
    .btn {
      background-color: $primary;
      //border: 1px solid #dddddd;
      margin-left: 10px;
      color: $white;
      border-radius: 4px;
    }

    .dropdown-menu {
      width: 96%;
    }
  }

  @media (min-width: 1024px) {
    .travelPlanModal .modal-dialog {
      margin: 0 0 0 auto;
      height: 100vh;
      width: 55%;

      .modal-content {
        height: 100%;
      }

      .text-left {
        text-align: left !important;
      }
    }
  }
}
