table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: -webkit-fill-available;
}

td,
th {
    border: 1px solid #000000;
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f07a19;
    color: white;
}

button {
    display: block;
    border: 1px solid darkgray;
    background-color: rgb(240, 122, 25);
    color: rgb(255, 255, 255);
    padding: 4px 12px;
}