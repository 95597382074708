@import '../../../../resources/styles/variables.scss';
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    color: #808292;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  //opacity: 0;
  cursor: pointer;
  height: 20px;
  //width: 0;
  width: 20px;
  left: 0;
  top: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: solid 1px #767676;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid $primary;
}

/* When the checkbox is checked, add a gray background */
.checkbox-container input:disabled ~ .checkmark {
  background-color: #e0e1dd;
  border: #e0e1dd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  //border-width: 0 1px 1px 0;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid $primary;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
