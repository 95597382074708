.item .item-image {
  width: 50px;
  height: 50px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.item .content .title {
  height: 12px;
  margin: 10px;
  width: 200px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.item .content .short-title {
  height: 12px;
  margin: 10px;
  width: 350px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

@keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@media (max-width: 767px) {
  .item .content .short-title {
    width: 100%;
  }
  .item .content .title {
    width: 70%;
  }
  .item .content {
    width: 100%;
  }
}
