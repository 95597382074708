.date-pricker-wrapper {
 input{
   width: 100%;
   border-width: 1px;
   padding: 0.375rem 0.75rem;
   background: url("../../../../resources/images/calendarIcon.svg") no-repeat right center;
   background-size: 20% 40%;
   cursor: pointer;
 }
 .react-datepicker__month-dropdown-container{
   margin-right: 20px;
 }
 .react-datepicker__month-read-view--down-arrow{
   top:3px;
 }

}
