.image-display-list {
  display: flex;
  flex-wrap: wrap;

  .image-item {
    overflow: hidden;
    margin: 0 20px 20px 0;
    position: relative;

    .remove-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #ffffff;
      color: #dc3545;
      border-radius: 50% !important;
      border: 1px solid #dddddd;
      height: 35px;
      width: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: auto;

      .fa {
        font-size: 14px;
      }
    }

    img {
      width: 100px;
      height: 100px;
      @media (min-width: 768px) {
        width: 160px;
        height: 160px;
      }
      margin-bottom: 15px;
      object-fit: contain;
      object-position: center;
      border: solid 1px #cccccc;
    }

    .pdf {
      width: 160px;
      height: 160px;
      margin-bottom: 15px;
      object-fit: contain;
      object-position: center;
      border: solid 1px #cccccc;
      background: #ddd;
    }

    .pdf-file {
      padding: 60px 10px 0 10px;
      width: 100%;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    .icon {
      text-align: center;
      margin-top: 10px;

      i {
        font-size: 50px;
        color: gray;
      }
    }
  }
}
