@import '../../../../resources/styles/variables.scss';

.timeLineTaskModal {
  &.add-categoryModal {
    .modal-dialog {
      margin: 0 0 0 auto;
      height: 100vh;
      width: 100%;
      @media (min-width: 1024px) {
        width: 60%;
      }
    }

    .modal-content {
      height: 100%;
    }
  }
}
#recruitmentrecordStatus{
  .modal-dialog {
  @media (min-width: 1024px) {
    width: 100%;
  }
}
}
