@import "./variables.scss";
@import "./mixins.scss";

.common-button {
  @include commonBtn($white, $primary, $primary, 0.9);
}

.common-save-button {
  @include commonBtn($white, $green, $green, 0.9);
}

.common-next-button {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
  font-size: 0.875rem;
  min-height: 30px;
  min-width: 100px;
  border-radius: 3px;

  &:hover {
    color: $white;
    opacity: 0.9;
  }
}

.common-back {
  font-size: 0.875rem;
  min-height: 30px;
  min-width: 100px;
  border-radius: 3px;
}

.common-prev {
  color: #404250;
  font-size: 0.875rem;
  font-weight: bold;
}

.common-box-shadow {
  box-shadow: 0 1px 5px $border;
  border-radius: 3px;
}

.btn-success.btn-main {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  font-size: 0.875rem;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;
    background-color: $primary !important;
    border-color: $primary !important;
    outline: 0;
    box-shadow: none;
  }
}
