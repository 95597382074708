@import '../styles/variables.scss';

@mixin mediaq($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin minmediaq($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin defaultFontStyle($fontfamily: null, $fontSize: null, $lineHeight: null) {
  font-family: $fontfamily;
  font-style: normal;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
}

@mixin fontSize($fontSize: null, $lineHeight: null, $fontWeight: null) {
  font-style: normal;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
  font-weight: ($fontWeight);
}

@mixin defaultFont($fontSize: null, $lineHeight: null) {
  font-style: normal;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
}

@mixin notificationColor($borderColor: null, $backgroundColor: null) {
  border-color: ($borderColor);
  background-color: ($backgroundColor);
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin commonBtn($color, $backgroundColor,$borderColor,$opacity ) {
  font-size: 0.875rem;
  min-height: 30px;
  min-width: 100px;
  border-radius: 3px;
  color: $color;
  background-color: ($backgroundColor);
  border-color: ($borderColor);
  &:hover, &:focus, &:active {
    color: $color;
    background-color: ($backgroundColor);
    border-color: ($borderColor);
    opacity: $opacity;
  }
}
