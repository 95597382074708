@import '../../../../resources/styles/variables.scss';

.customCheck {
  label {
    font-size:  0.875rem;
    font-weight: 500;
  }
}

.radio {
  .radio-container {
    display: inline-block;
    position: relative;
    padding: 0 25px;
    cursor: pointer;
    font-size:  0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      cursor: pointer;
      width:  0.875rem;
      left: 0;
    }

    .checkmark {
      border: solid 1px transparent;
      position: absolute;
      top: 0;
      left: 0;
      height: 14px;
      width: 14px;
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary;
      }
    }

    .label {
      position: relative;
      top: -4px;
      font-size:  0.875rem;
      color: #808292;
    }
  }


  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: $white;
    border-color: $primary;

  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

}

input:focus {
  outline: 2px auto #fecab2;
}
