@import "./resources/styles/variables.scss";
@import "resources/styles/common.scss";
@import "resources/styles/mixins.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "bootstrap/scss/bootstrap-reboot.scss";
@import "font-awesome/scss/font-awesome.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #030229;
  overflow: hidden;
}

html {
  font-size: 16px;
}

.Side-menu .item .children {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 5px;
  margin: 0px 15px;
  justify-content: flex-end;
}

.Side-menu-default *:not(i) {
  font-family: "Lato", sans-serif !important;
}

.custom-sidebar {
  .Side-menu {
    max-width: inherit;
  }
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.input-field {
  label {
    color: #404250;
    font-size: 0.875rem;
    font-weight: 500;
  }

  input {
    height: 44px;
    border-radius: 3px;
    font-size: 0.875rem;
    border-color: rgba(0, 0, 0, 0.1);

    &:focus {
      box-shadow: none;
    }
  }

  .text-danger {
    font-size: 0.875rem;
  }

  textarea {
    border-radius: 3px;
    font-size: 0.875rem;
    border-color: rgba(0, 0, 0, 0.1);
    min-height: 100px;
  }
}

.react-date-picker .react-date-picker__wrapper {
  border-radius: 3px;
}

.inputField-label {
  color: #404250;
  font-size: 0.875rem;
  font-weight: 500;

  .text-danger {
    font-size: 0.875rem;
  }
}

.react-date-picker {
  font-size: 0.875rem;
}

.app-dropdown {
  label {
    color: #404250;
    font-size: 0.875rem;
    font-weight: 500;
  }

  div[class$="container"] {
    font-size: 0.875rem;
  }

  div[class$="control"] {
    min-height: 44px;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    div[class$="ValueContainer"] {
      min-height: 40px;

      div[class$="singleValue"] {
        color: #495057;
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .text-danger {
    font-size: 0.875rem;
  }
}

.addonslist-table {
  border: 1px solid #ddd;
}

.searchFilter {
  .searchWrapper {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.selectedFilter {
  .filterTextWrapper {
    margin-top: 20px;
    align-items: center;
    flex-wrap: wrap;

    .filterBadge {
      font-size: 0.875rem;
    }

    .badges {
      margin-bottom: 6px;
      background-color: $filterBadgeBackground;
      color: $filterBadgeText;
      padding: 2px 10px;
      border-radius: 10px;
      margin-right: 10px;

      label {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.table-container {
  padding: 0;

  .topFilter {
    width: 100%;
    flex-wrap: wrap;

    .searchWrapper {
      display: flex;

      @media (max-width: 767px) {
        display: block;
      }

      h3 {
        margin-right: 20px;
        font-size: 2.125rem;
        font-weight: bold;

        @media (max-width: 767px) {
          font-size: 1.563rem;
          margin-right: 10px;
          margin-bottom: 8px;
        }
      }

      input {
        height: 30px;
        border-radius: 3px !important;
        font-size: 0.813rem;
        min-width: 250px;
        background: url("./resources/images/searchIcon.svg") no-repeat left
          center #ffffff;
        padding-left: 25px;
        padding-right: 10px;
        background-position-x: 5px;
        border: 1px solid $border;
      }
    }
  }

  tr {
    i {
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  div[class$="rdt_TableRow"] {
    min-height: 50px;
    border-bottom-width: 1;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 5px #ebedf0;
    border-radius: 3px;
  }

  div[class$="rdt_TableCell"] {
    font-size: 0.85rem;
    font-weight: 500;
  }

  div[class$="TableCol_Sortable"] {
    font-size: 0.85rem;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .rdt_Pagination {
    border: 0;
    background-color: transparent;
    color: #1b1d2a;
  }

  div[class$="rdt_TableBody"] {
    background-color: #f7f8fa;
    border-bottom: 0;
  }
  div[class$="rdt_TableRow"] {
    background-color: #f3f3f3;
    margin-bottom: 5px;
  }

  div[class$="TableHeadRow"] {
    min-height: 49px;
    border-bottom: 0;
  }

  .rdt_TableHeader {
    min-height: auto;
  }

  .rdt_Table {
    margin-top: 10px;
  }

  .hlidmt {
    padding: 0;
    margin-bottom: 10px;
  }

  div[class$="TableCol"] {
    color: #fff;
  }


  .export-btn {
    font-size: 14px;
    border-radius: 0;
    min-width: 100px;
    position: absolute;
    left: 0;
    top: 12px;
    z-index: 10;

    @media (max-width: 767px) {
      position: relative;
      top: 0;
    }
  }
}

.main-content {
  margin-bottom: 40px !important;

  .fa-pencil,
  .fa-trash,
  .fa-toggle-off,
  .fa-clone,
  .fa-toggle-on,
  .fa-key,
  .fa-refresh {
    font-size: 0.875rem;
    cursor: pointer;
  }

  .modal-body .fa-trash {
    font-size: 1rem;
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.fw-500 {
  font-weight: 500;
}

.main-container {
  height: calc(100vh - 99px);
  overflow-y: auto;

  .commonBody {
    min-height: 85vh;
  }
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.check-container input:checked ~ .checkmark {
  background-color: #ffffff !important;
  border: 1px solid $primary;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-container input:checked ~ .checkmark:after {
  display: block;
}

.check-container {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;

    &:after {
      left: 6px;
      top: 1px;
      width: 6px;
      height: 12px;
      border: solid $primary;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loader_wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &.mainLoader {
    background: rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }

  &.mainLoader {
    background: rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }

  .spinner-grow {
    width: 3rem;
    height: 3rem;
    z-index: 1032;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
    z-index: 1032;
  }
}

.warningNote {
  font-size: 0.875rem;
  font-weight: 700;
  color: grey;
  margin-bottom: 10px;
}

.infoIcon i {
  font-size: 1.875rem;
  cursor: pointer;
  border-radius: 20px;
}

.main-content h3 {
  margin-bottom: 0;
}

.table-wrapper {
  .lastIconRow {
    width: 100%;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    gap: 10px;

    .innerImgBox {
      margin-right: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 3px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      img {
        min-width: 20px;
      }

      &.statusToggle {
        cursor: auto;
      }
    }
  }

  @media (max-width: 767px) {
    .w-25 {
      width: 75% !important;
    }
  }
}

.userCustomDropdown {
  @media (max-width: 1024px) {
    .col-sm-4 {
      margin-bottom: 15px;
    }
  }
}

.h-30 {
  height: 30px;
}

.table-header {
  color: "#F36633";
  font-weight: "700";
}


.rdt_Table {
  .rdt_TableCell {
    div:first-child {
      white-space: pre-wrap;

      @media (max-width: 768px) {
        white-space: nowrap;
      }
    }

    &:last-child {
      div:first-child {
        white-space: nowrap;

        @media (max-width: 768px) {
          max-width: 300px;
        }
      }
    }
  }

  .showEllipsis {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fs-12 {
  font-size: 0.75rem;
}

.main-content .btn.btn-danger,
.btn.btn-success,
.form-control:disabled,
.form-control[readonly] {
  font-size: 0.875rem;
  border-radius: 3px;
}

.no-data {
  font-size: 1.125rem;
  margin-top: 30px;
}

.main-container,
.table-wrapper header {
  background-color: #ffffff;

  .main-content {
    padding: 15px;

    &.create {
      border-radius: 5px;
      background-color: #ffffff;
      min-height: calc(100vh - 99px);
      margin: 15px 0;
    }
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  height: 44px;
  padding: 0.375rem 0.75rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  border: 1px solid #aaa;
  width: 100%;
  border-radius: 3px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 44px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 10px;
  font-size: 0.875rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.br-3 {
  border-radius: 3px;
}

.searchFilter {
  .search_filter_text {
    position: absolute;
    top: -15px;
    background: #e5e5e5;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
}

.advanceSearch {
  color: $white;
  background-color: $primary;
  border: 1px solid $primary;
  margin-left: 20px;
  border-radius: 4px;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: $white;
    opacity: 0.8;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-decoration {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.fa.pdfDownload {
  font-size: 1.125rem;
  color: #333333;
}

.validTaskDate {
  padding: 2px 6px;
}

.fs-25 {
  font-size: 1.563rem !important;
}

.fs16 {
  font-size: 1rem;
}

.fs18 {
  font-size: 1.125rem !important;
}

.react-time-picker__button,
.react-date-picker__button {
  svg {
    stroke: #333;
    opacity: 0.7;
    width: 15px;
  }
}

.common-box-shadow {
  .btn {
    font-size: 0.875rem;
    min-height: 30px;
    min-width: 100px;
    border-radius: 3px;

    &.common-next-button {
      background-color: #263237;
      border-color: #263237;
      color: #ffffff;
    }

    &.common-button {
      color: #ffffff;
      background-color: $primary;
      border-color: $primary;
    }

    &.common-save-button {
      color: #ffffff;
      background-color: #28a745;
      border-color: #28a745;
    }
  }

  .min-width-auto {
    min-width: auto;
  }
}

.passwordIcon {
  position: absolute;
  right: 30px;
  top: 45px;
}

.align-items-center {
  .btn-link {
    color: #2780e3;
    border: 1px solid #dddddd;
    padding: 3px 10px;
    border-radius: 5px;

    &:hover {
      color: #2780e3;
    }
  }
}

.btn-link {
  color: #2780e3;

  &:hover {
    color: #2780e3;
  }
}

.overviewWrapper label {
  margin-bottom: 3px;
}

.imageWrapper {
  text-align: center;

  img {
    width: 50%;
    margin-bottom: 10px;
  }
}

.recruitmentRecordTable {
  tr {
    td {
      word-break: break-word;

      &:first-child {
        color: $primary;
      }
    }
  }
}

.userGroupInnerTable div[class$="TableCol_Sortable"],
.sourcingTableList th,
.sourcingDetailTabForm th {
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.rdt_ExpanderRow
  .innerDataTable
  .rdt_Table
  div[class$="rdt_TableBody"]
  div[class$="rdt_TableRow"] {
  min-height: 50px !important;
  box-shadow: none;
  border-bottom: 1px solid #dddddd;
}

.innerDataTable {
  padding: 10px 15px;

  header {
    display: none;
  }
}

.table-container .rdt_TableHeader,
.table-container header {
  display: none;
}

.react-grid-Container {

  .react-grid-Main {

    .widget-HeaderCell__value {
      text-transform: uppercase;
      font-size: 0.813rem;
      color: $primary;
    }
  }

  .react-grid-Toolbar {
    button {
      color: $primary;
      border-radius: 4px;

      &:hover {
        color: $primary;
        opacity: 0.8;
      }
    }
  }
}

.bellCount {
  position: relative;
  top: -10px;
  left: 6px;
  color: $primary;
}

.bellCountIcon {
  height: 25px;
  width: 25px;
  padding: 3px 4px;
  border-radius: 50%;
  border: 1px solid;
  text-align: center;
}

.mw-170 {
  max-width: 170px;
}

.inqForm {
  &.mandateTable {
    div[class$="rdt_TableCell"],
    div[class$="rdt_TableCol"] {
      min-width: 140px;

      &:last-child {
        min-width: 180px;
      }
    }
  }
}

.overflow {
  max-height: 100vh;
  overflow-y: auto;
}

.btns.btn {
  padding: 0;
  min-width: auto;
  min-height: auto;
}

.table-responsive {
  @media (max-width: 768px) {
    td,
    th {
      white-space: nowrap;
      overflow-y: auto;
    }
  }
}

@media (max-width: 767px) {
  .btn.text-right.cursor-pointer,
  .btn.btn-link.mr-3 {
    img {
      position: absolute;
      right: 15px;
      top: 0;
    }
  }

  .tabListScroll {
    max-height: 150px;
    margin-bottom: 15px;
    overflow-y: auto;
  }
}

.poeTable {
  .table-responsive {
    overflow-x: initial;
  }
}

@media (max-width: 1023px) {
  .navbar-nav {
    .border-right {
      border-right: 0 !important;
    }
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: row;
  }
}

.btn {
  &.button-md {
    min-width: 150px;
    padding: 8px;
    font-size: 1rem;
  }
}

.hrLine {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.exportBtn {
  align-self: flex-end;
  position: absolute;
  right: 30px;
  top: 10px;

  i {
    font-size: 1.15rem;
  }
}

.dashboardExportBtn {
  position: absolute;
  font-size: 12px;
  top: 8px;
  right: 60px;
  z-index: 1;
  text-align: center;
  box-shadow: 2px 2px 3px #999;

  i {
    font-size: 12px;
    margin-left: 5px;
  }

  padding: 4px 8px;
}

.header-fixed {
  z-index: 1001;
  background-color: $headerBackground !important;
  border: solid 1px $headerBorder;
  color: $headerText;
  padding: 0 1rem;

  .navbar-brand {
    color: $headerText;
  }

  .btn-link {
    color: $headerText;
  }
}

.custom-sidebar .item.active {
  color: #ffffff !important;
}
.custom-sidebar {
  background-color: $sidebarBackground;

  .item {
    color: #000000 !important;
    &.active {
      color: $sidebarActiveText !important;
      background: $sidebarActiveBackground;
    }

  }
}

.table-container div[class$="TableHeadRow"] {
  background-color: $tableHeaderBackground;
}

.table-container div[class$="TableCol_Sortable"] {
  color: $tableHeaderText;
}

.table {
  thead {
    th {
      border-bottom: 0;
      background-color: $tableHeaderBackground;
      color: $tableHeaderText;
    }
  }

  td {
    color: $tableRowText;
  }
}

.header-profile-icon {
  border-radius: 100%;
  padding: initial;
  background-color: #f36633;
  color: white;
  margin: 0px 5px;
  vertical-align: super;
  font-size: 19px;
  width: 30px;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
}

.charts {
  padding: 10px 20px;
}

.dropdown-btn {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  right: 15%;
}

.dropdown-content {
  font-size: 0.875rem;
  display: none;
  position: absolute;
  background-color: $white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: -20%;
  right: 0;
  top: 80%;
  margin: 5px;
}

.dropdown-content a {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  border: 1px solid $border;
}

.dropdown-content a:hover {
  background-color: $sidebarHover;
}

.dropdown-content p {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  border: 1px solid $border;
  margin: 0;
  cursor: pointer;
}

.dropdown-content p:hover {
  background-color: $sidebarHover;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.edit-profile {
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 2px;
  font-size: 15px;
  color: $primary;
  padding: 5px;
}

.fs18 {
  font-size: 18px;
}

.deleteModal .btn.btn-danger {
  font-size: 1rem;
}

.section {
  font-weight: bold;

  .line {
    margin-top: 5px !important;
    border: 1px solid #00a5f8;
  }
}

.main-content .btn.btn-danger,
.btn.btn-success,
.form-control:disabled,
.form-control[readonly] {
  font-size: inherit;
}

.innerImgBox > .checkbox {
  height: 16px;
  width: 16px;
  margin-top: 2px;
  margin-right: 8px;
}

.ScrollbarsCustom-Content {
  position: relative;
}

.rdt_TableCol:empty {
  display: none;
}

.gelpCx {
  &::-webkit-scrollbar {
    height: 4px !important;
    background-color: #e9eef3;
  }

  &::-webkit-scrollbar-thumb {
    background: #f36734 !important;
    border-radius: 5px !important;
  }

  border-radius: 5px;
  overflow-y: hidden !important;
  overflow-x: auto !important;
}
.selectBox {
  height: 30px;
}
